import React from "react";
import "./index.css";

export default function Heading3() {
  return (
    <div>
      <div className="text3">
        <div className="headingContainer3">
          <p className="heading3">Meet the hosts</p>

          <div className="headingBackground3"></div>
        </div>
      </div>
    </div>
  );
}
